import React, {useContext, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {AppContext} from '../../contexts/AppContext/AppContext';
import {openChannelIO} from '../../utils/commonUtils';
import {HUNDRED_SHOP_SLUG} from '../../constants/constants';
import './Footer.scss';

const Footer = () => {
    const {rootStore} = useContext(AppContext);
    const {
        initData,
        setIsLoginModalOn,
        setSignUpModalOn,
        setIsAgreementModalOn,
        setAgreementType,
    } = rootStore;
    const [openCategoryBox, setOpenCategoryBox] = useState(false);

    const history = useHistory();

    const getCurrentYear = () => {
        return new Date().getFullYear();
    }

    return (
        <footer className="Footer">
            <div className="categoryWrapper mb">
                <h2
                    onClick={() => {
                        setOpenCategoryBox((prev) => !prev);
                    }}
                    className="allProductCategory"
                >
                    제품 카테고리
                    <img
                        src={
                            openCategoryBox
                                ? '/img/m-icon-footer-minus-plus-24.svg'
                                : '/img/m-icon-footer-more-plus-24.svg'
                        }
                        alt="open category box"
                    />
                </h2>
            </div>
            {openCategoryBox && (
                <div className="categoryBox">
                    {initData.topMenu?.map((menu, idx) => {
                        // if (
                        //     menu.slug === HUNDRED_SHOP_SLUG &&
                        //     initData.isLogin &&
                        //     initData.isWelcomeSpecialPriceCouponUsed
                        // )
                        //     return;
                        if (menu.slug !== 'home') {
                            return (
                                <div
                                    onClick={() => history.push(menu.link)}
                                    key={idx}
                                >
                                    {menu.name}
                                </div>
                            );
                        }
                    })}
                </div>
            )}
            <div className="upperLayout">
                <div className="categoryWrapper dt">
                    {initData &&
                        initData.topMenu?.map((menu, idx) => {
                            // if (
                            //     menu.slug === HUNDRED_SHOP_SLUG &&
                            //     initData.isLogin &&
                            //     initData.isWelcomeSpecialPriceCouponUsed
                            // )
                            //     return;
                            if (menu.slug !== 'home') {
                                return (
                                    <div
                                        onClick={() => history.push(menu.link)}
                                        className="categoryItem"
                                        key={idx}
                                    >
                                        {menu.slug === 'box'
                                            ? 'BACONBOX'
                                            : menu.name}
                                    </div>
                                );
                            }
                        })}
                </div>
                <div className="companyMenus dividerWrapper">
                    {!initData?.isWelcomeSpecialPriceCouponUsed && (
                        <>
                            <span
                                onClick={() => history.push('/welcome')}
                                className="first"
                            >
                                회원가입 이벤트
                            </span>
                            <div className="dividerLine"/>
                        </>
                    )}
                    <span onClick={() => history.push('/partner')}>
                        제휴 파트너 구매
                    </span>
                    <div className="dividerLine"/>
                    <span onClick={() => openChannelIO()}>채팅 상담</span>
                    <div className="dividerLine"/>
                    <a
                        href="https://www.notion.so/gitt/7d3c34cbc9114570b4101a71255710ce"
                        target="_blank"
                    >
                        <span>도움말</span>
                    </a>
                    <div className="dividerLine"/>
                    <a
                        href="https://www.notion.so/gitt/gitt-Company-643e5bf4e2984f91a21b190067ba2671"
                        target="_blank"
                    >
                        <span>회사소개</span>
                    </a>
                    <div className="dividerLine"/>
                    <a
                        href="https://gitt.notion.site/1f631141324e42a2b57b952b3980ec1d"
                        target="_blank"
                    >
                        <span className="last">채용 공고</span>
                    </a>
                </div>
            </div>

            <div className="bottomLayout">
                <div className="companyInfo">
                    <img
                        className="baconLogo"
                        src="/img/bacon-logo.svg"
                        alt="bacon logo"
                    />
                    <h3 className="companyName">주식회사 깃컴퍼니</h3>
                    <div className="infoContent">
                        서울 성동구 성수일로 8길 59, B동 8층 802호<br/>
                        사업자등록번호: 157-86-00367 <br/>
                        통신판매업신고: 2018-서울강남-04755 <br/>
                        <div className="dividerWrapper">
                            대표: 박성민
                            <span className="dividerLine "/>
                            개인정보책임자: 이광배 <br/>
                        </div>
                        <div className="dividerWrapper">
                            TEL: 1522-8067 <span className="dividerLine"/>{' '}
                            Email:{' '}
                            <a href="mailto:hello@baconbox.co" target="_blank">
                                hello@baconbox.co
                            </a>
                        </div>
                    </div>
                    <div className="companyPolicy">
                        <span
                            onClick={() => {
                                history.push('?privacy=true')
                                setIsAgreementModalOn(true);
                                setAgreementType('personalPolicy');
                            }}
                        >
                            개인정보취급방침
                        </span>
                        <span
                            onClick={() => {
                                history.push('?terms=true')
                                setIsAgreementModalOn(true);
                                setAgreementType('terms');
                            }}
                        >
                            이용약관
                        </span>
                    </div>
                    <span className="copyright">© {getCurrentYear()} Gitt Company</span>
                </div>
                <div className="socialIconsAndBtns">
                    <div className="socialBtns">
                        <a
                            href="https://www.instagram.com/dogslovebacon/"
                            target="_blank"
                        >
                            <img
                                src="/img/instagram.svg"
                                alt="open instagram link"
                            />
                        </a>
                        <a
                            href="https://www.facebook.com/baconbox.co/"
                            target="_blank"
                        >
                            <img
                                src="/img/facebook.svg"
                                alt="open facebook link"
                            />
                        </a>
                        <a href="https://pf.kakao.com/_YWDxnj" target="_blank">
                            <img src="/img/kakao.svg" alt="open kakao link"/>
                        </a>
                    </div>
                    {initData && !initData.isLogin && (
                        <div className="accountBtns">
                            <button
                                onClick={() => setIsLoginModalOn(true)}
                                type="button"
                                className="loginBtn"
                            >
                                로그인
                            </button>
                            <button
                                onClick={() => setSignUpModalOn(true)}
                                type="button"
                                className="signUpBtn"
                            >
                                회원가입
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </footer>
    );
};

export default Footer;
