import {observer} from 'mobx-react';
import React, {useCallback, useEffect, useState} from 'react';
import {AppContext} from '../../contexts/AppContext/AppContext';
import {KAKAO_AUTH_URL, openChannelIO, stringLineBreak, validateEmail} from '../../utils/commonUtils';
import closeIcon from '../../assets/m-icon-gnb-btn-popup-close.svg';
import './LoginContent.scss';
import InputWithValidation from '../InputWithValidation/InputWithValidation.component2';
import Button from '../Button/Button';
import {fetchAndReceiveJSON} from '../../utils/ajaxUtils';
import {
    LOGIN_URL,
    LOGIN_KAKAO_URL,
} from '../../constants/constants';
import MIconLinkArrow10 from '../../assets/m-icon-link-arrow-10-black.svg';
import {
    RESPONSE_REGISTER_BILLING_PHONE_ALREADY_EXIST,
    RESPONSE_REGISTER_BILLING_PHONE_ALREADY_EXIST_MSG,
    RESPONSE_STATUS_FAIL_LOGIN,
    RESPONSE_STATUS_FAIL_LOGIN_OBJ
} from '../../constants/errorConstants';
import LoginCustomPopup from './LoginCustomPopup';

const LoginContent = observer(({
                                   setIsLoginContent
                               }
) => {
    const {rootStore, cartPageStore} = React.useContext(AppContext);
    const {
        isLoginModalOn,
        setIsLoginModalOn,
        setIsBodyScrollable,
        setIsSpinnerOn,
        setIsDimBgOn,
    } = rootStore;
    const {
        orderForNonUser,
        cartInfoList,
        setOrderForNonUser,
        setGoToOrder,
        setPayForNpay,
    } = cartPageStore;
    const userAgent = navigator.userAgent.toLowerCase();

    const [isEmailLogin, setIsEmailLogin] = useState(false);
    const [loginFailMsg, setLoginFailMsg] = useState(null);
    const [userEmail, setUserEmail] = useState('');
    const [userPassword, setUserPassword] = useState('');
    const [validationCheck, setValidationCheck] = useState(false);

    const [loginCustomPopupData, setLoginCustomPopupData] = useState(null);
    const [userData, setUserData] = useState(null);

    const [errorMsgs, setErrorMsgs] = useState({
        email: "",
        password: "",
    })

    const resetState = useCallback(() => {
        setUserEmail('');
        setUserPassword('');
        setValidationCheck(false);
        setLoginCustomPopupData(null);

    }, []);

    useEffect(() => {
        setIsBodyScrollable(!isLoginModalOn);
    }, [isLoginModalOn]);

    useEffect(() => {
        setKakaoToken()
    }, [])

    const login = useCallback(async ({userEmail, userPassword}) => {

        if (!validateLogin(userEmail, userPassword)) {
            displayErrorMsg(userEmail, userPassword);
            return;
        } else {
            //error msg reset
            setErrorMsgs({
                email: '',
                password: ''
            });
        }

        setIsSpinnerOn(true);
        const res = await fetchAndReceiveJSON({
            url: LOGIN_URL,
            method: 'POST',
            body: {email: userEmail, password: userPassword},
        });

        if (res.id) {
            location.reload();
            if (location.pathname === 'checkout') {
                setGoToOrder(true);
            }
        } else if (res === RESPONSE_STATUS_FAIL_LOGIN) {
            setLoginFailMsg(RESPONSE_STATUS_FAIL_LOGIN_OBJ.RESPONSE_STATUS_FAIL_LOGIN);
        }

        setUserPassword('');
        setIsSpinnerOn(false);

        return res;
    }, []);

    const validateLogin = (userEmail, userPassword) => {

        return userEmail && userPassword;
    }

    const displayErrorMsg = (userEmail, userPassword) => {

        const errors = {
            email: '',
            password: ''
        };

        if (!userEmail) {
            errors.email = "아이디를 입력해주세요.";
        }

        if (!validateEmail(userEmail)) {
            errors.email = "이메일을 올바르게 입력해 주세요";
        }

        if (!userPassword) {
            errors.password = "비밀번호를 입력해주세요.";
        }

        setErrorMsgs(errors);

    }
    //
    // function kakao_auth() {
    //     window.Kakao.Auth.login({
    //         success: function (response) {
    //             kakao_user();
    //         },
    //         fail: function (error) {
    //             console.error(error);
    //             alert('카카오 로그인중 오류가 발생 하였습니다.');
    //             setIsSpinnerOn(false);
    //         },
    //     });
    // }


    function kakao_auth() {
        if (userAgent.indexOf("iphone") > -1 ||
            userAgent.indexOf("ipad") > -1 ||
            userAgent.indexOf("ipod") > -1) {
            window.Kakao.Auth.authorize({
                redirectUri: window.location.origin,
                state: window.location.pathname
            })
        } else {
            window.location.href = KAKAO_AUTH_URL(window.location.origin, String(window.location.pathname))
        }
    }


    async function getKakaoToken(code, state) {
        const data = {
            grant_type: 'authorization_code',
            client_id: process.env.REACT_APP_KAKAO_APP_KEY,
            redirect_uri: window.location.origin,
            code: code,
        };
        fetch("https://kauth.kakao.com/oauth/token", {
            method: "POST",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            },
            body: new URLSearchParams(data).toString()
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(response => {
                window.Kakao.Auth.setAccessToken(response.access_token);
                kakao_user(state, response.access_token);
            })
            .catch(error => {
                alert('카카오 로그인 중 오류가 발생했습니다.');
            });
    }


    function kakao_user(state, token) {
        setIsSpinnerOn(true);

        window.Kakao.API.request({
            url: '/v2/user/me',
            success: function (response) {
                // user_data = response;

                // setUserData(response);
                const data = response
                data.kakao_account.token = token
                kakao_login(data, '', state);
            },
            fail: function (error) {
                console.error(error);
                alert('카카오 로그인중 오류가 발생 하였습니다.');
                setIsSpinnerOn(false);
            },
        });
    }

    function setKakaoToken() {
        const searchParams = new URLSearchParams(window.location.search);
        const code = searchParams.get('code')
        const state = decodeURI(searchParams.get('state'))
        if (code) {
            getKakaoToken(code, state)
        } else {
            return null
        }
    }

    async function kakao_login(user, merge, state) {
        // if (merge == '1') {
        //     setIsSpinnerOn(true);
        // }

        setIsSpinnerOn(true);

        //회원가입 request
        const res = await fetchAndReceiveJSON({
            url: LOGIN_KAKAO_URL,
            method: 'POST',
            body: {user: user, merge: merge},
        });

        // console.log("kakao_login ", res);

        if (res.success) {
            if (res.option == 'merge') {
                setIsLoginModalOn(true);
                setLoginCustomPopupData({
                    mainText: stringLineBreak(`이미 가입한 계정이 있어 
                        간편하게 로그인하실 수 있어요!`),
                    userData: user,
                    action: () => {
                        setLoginCustomPopupData(null);
                    },
                    actionBtnText: "취소하기",
                    action2: () => {
                        setLoginCustomPopupData(null);
                        kakao_login(user, '1', state);
                    },
                    actionBtnText2: "간편로그인 하기"
                })

                setIsSpinnerOn(false);
            } else if (res.option == 'unsub') {
                setIsLoginModalOn(true);
                setLoginCustomPopupData({
                    mainText: "이미 탈퇴한 회원입니다.",
                    userData: null,
                    action: () => {
                        setLoginCustomPopupData(null);
                    },
                    actionBtnText: "확인",
                    action2: () => {
                        setLoginCustomPopupData(null);
                        setIsLoginContent(false);
                    },
                    actionBtnText2: "회원가입 하기"
                })
                setIsSpinnerOn(false);
            } else {
                if (res.option == 'new') {
                    // 새로 가입 하는 경우 뜨는 페이지
                    setIsSpinnerOn(false);
                    setIsLoginModalOn(false);
                    location.href = '/welcome';
                } else {
                    location.href = state
                }
            }
        } else {

            let alertMsg = '카카오 로그인중 오류가 발생 하였습니다.';
            if (res === RESPONSE_REGISTER_BILLING_PHONE_ALREADY_EXIST) {
                alertMsg = RESPONSE_REGISTER_BILLING_PHONE_ALREADY_EXIST_MSG;
            } else if (res.msg) {
                alertMsg = res.msg;
            } else if (res.message) {
                alertMsg = res.message;
            }
            alert(alertMsg);

        }

        setIsSpinnerOn(false);

    }

    return (
        <div style={{minHeight: "inherit"}}>
            <div className="loginContent">
                <img
                    className="closeIcon"
                    src={closeIcon}
                    onClick={() => {
                        setIsLoginModalOn(false);
                        setIsEmailLogin(false);
                        setIsBodyScrollable(true);
                        setOrderForNonUser(false);
                        resetState();
                    }}
                />
                <div className="loginContentTop">
                    <div className="loginHeadText">
                        {stringLineBreak('로그인하시면 더 편리하게 \n 이용하실 수 있어요 :)')}
                    </div>
                    {/* <div className="loginSubText"> */}
                    {/* {isSignUp && isViaPartner
                                ? '앱을 통해 손쉽게, 저렴한 가격으로 대량 구매가 가능합니다.'
                                : `신규 가입 시 할인 쿠폰 + 인기 장난감을 드려요!`} */}
                    {/* </div> */}
                    <div className="loginMethodWrapper">

                        {orderForNonUser && cartInfoList.npay_available && (
                            <div className="naverPayContainer">
                                <div className="loginMethodDesc">회원가입 없이 바로 구매하기!</div>
                                <div
                                    className="naverPayButton"
                                    onClick={() => {
                                        setIsLoginModalOn(false);
                                        setPayForNpay(true);
                                    }}
                                >
                                    <img
                                        src="/img/common-logo-npay-white@3x.png"
                                        alt="naverPayButton"
                                    />
                                </div>
                            </div>
                        )}

                        <div className="loginMethodDesc">플러스 친구 추가하면 할인쿠폰 증정!</div>
                        <div className="loginMethods">
                            <div
                                className="loginMethod"
                                onClick={async () => {
                                    kakao_auth();
                                }}
                                style={{backgroundColor: '#fae301', marginTop: "6px", border: "unset"}}
                            >
                                <div className="loginLogo">
                                    <img
                                        src="/img/icon-login-kakao-2.svg"
                                        alt=""
                                    />
                                </div>
                                <div className="loginMethodText">
                                    {orderForNonUser
                                        ? '카카오 계정으로 주문하기'
                                        : '카카오 간편 로그인하기'
                                        // : '카카오 계정으로 시작하기'
                                    }
                                </div>
                            </div>
                            <div
                                className={`loginMethod ${isEmailLogin ? 'hide' : ''}`}
                                onClick={() => {
                                    setIsEmailLogin(!isEmailLogin);
                                }}
                            >
                                <div className="loginLogo">
                                    <img
                                        src="/img/icon-login-email.svg"
                                        alt=""
                                    />
                                </div>
                                <div className="loginMethodText">
                                    {orderForNonUser
                                        ? '이메일로 주문하기'
                                        : '이메일로 로그인하기'
                                    }
                                </div>
                            </div>
                        </div>

                        <div
                            className={`emailInput ${isEmailLogin ? 'open' : ''
                            }`}
                        >
                            <form
                                action=""
                                onSubmit={(e) => {
                                    e.preventDefault();
                                }}
                                style={{width: '100%'}}
                            >
                                <InputWithValidation
                                    setStateFunction={setUserEmail}
                                    targetState={userEmail}
                                    placeHolder={'이메일 주소'}
                                    errMsg={
                                        errorMsgs.email
                                    }
                                    inputType={'email'}
                                />
                                <div
                                    className={`loginPasswordWrapper existing`}
                                >
                                    <InputWithValidation
                                        setStateFunction={
                                            setUserPassword
                                        }
                                        targetState={userPassword}
                                        placeHolder={'비밀번호'}
                                        errMsg={
                                            errorMsgs.password
                                        }
                                        inputType={'password'}
                                    />
                                </div>

                                {loginFailMsg && <div className="loginFailMsg">{loginFailMsg}</div>}

                                <Button
                                    className="emailLoginButton"
                                    withShadow
                                    onClick={async () => {
                                        setValidationCheck(
                                            !validationCheck
                                        );
                                        const res = await login({
                                            userEmail,
                                            userPassword,
                                        });

                                        if (orderForNonUser) {
                                            if (res?.id) {
                                                setIsDimBgOn(
                                                    true
                                                );
                                                setIsLoginModalOn(
                                                    false
                                                );
                                                // setIsSpinnerOn(false);
                                                setOrderForNonUser(
                                                    false
                                                );
                                            }
                                        }
                                    }}
                                    type={'submit'}
                                >
                                    로그인 하기
                                </Button>
                            </form>
                        </div>

                        <div className="findEmailText"
                             onClick={() => {
                                 openChannelIO();
                             }}>
                            가입한 ID, 비밀번호가 기억나지 않으세요? <br/>
                            <span className="underLine">
                                상담으로 도와드릴게요.
                            </span>
                        </div>

                    </div>
                </div>

                <div className="loginBottomArea">
                    <div className="loginBottomHeader">
                        아직 회원가입을 안하셨나요?
                    </div>

                    <div className="loginBottomDesc">
                        지금 카카오로 회원가입하시면 <br/>
                        바로 사용 가능한 <span style={{fontWeight: 'bold'}}>혜택</span>을 드리고 있어요!
                    </div>

                    <div className="loginToggleBtn"
                         onClick={() => {
                             setIsLoginContent(false);
                         }}
                    >
                        회원가입 하기
                        <img src={MIconLinkArrow10} alt=""/>
                    </div>
                </div>
            </div>

            {loginCustomPopupData && <LoginCustomPopup
                data={loginCustomPopupData}
            />}
        </div>
    );
});
export default LoginContent;
