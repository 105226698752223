import React, {
    FunctionComponent,
    ReactNode,
    useEffect,
    useState,
} from 'react';
import './CheckWithValidation.scss';
import activeIcon from "../../assets/m-btn-check-active.svg"
import defaultIcon from "../../assets/m-btn-check-defualt.svg"

const CheckWithValidation: FunctionComponent<{
    setStateFunction: CallableFunction;
    errMsg: string;
    targetState: boolean;
    text: string | ReactNode;
    className?: string;
}> = ({setStateFunction, errMsg, targetState, text, className}) => {

    return (
        <div className={`${className ? className : ''} `}>
            <div className="check-container">
                <img
                    style={{
                        cursor: 'pointer', width: "20px", height: "20px"
                    }}
                    src={targetState ? activeIcon : defaultIcon}
                    onClick={() => {
                        setStateFunction(!targetState);
                    }}
                />
                <div className={`text ${targetState ? 'clicked' : ''} `}>
                    {text}
                </div>
            </div>

            {errMsg && <span className="errMsg errMsgCheck">{errMsg}</span>}
        </div>
    );
};

export default CheckWithValidation;
